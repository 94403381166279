<template>
    <div class="helpCenterDetails">
      
         <van-nav-bar
                title="问题解答"
                left-arrow
                @click-left="onClickLeft"
                class="dark_bg"
        />
      <div class="helpCenterDetails-container">
            <div class="container-Q">
                <div>Q：</div>
                <div>{{list.question}}</div>
            </div>
            <div class="container-A">
                <div class="A-title">A：</div>
                <div class="A-text">{{list.answer}}</div>
            </div>
      </div>
      <div class="helpCenterDetails-customer">没有解决问题？
          <!-- <span @click="customerChat" class="customer-text">
              联系客服
            </span> -->
        </div>
    </div>
</template>

<script>


export default{
    data() {
        return{
            list:{},
        }
    },
    created(){
        this.list = JSON.parse(this.$route.query.item);
        console.log(this.list);
    },
    methods:{
        onClickLeft() {
            this.$router.back();
        },
        customerChat(){
            this.$router.push("/customerChat")
        }
    }
}
</script>
<style lang="less" scoped>
   @import "../../../less/element.less";
    @red: #c83f3f;
    .checked {
        color: #c83f3f;
        position: absolute;
        top: 3.1 / 3.125rem;
        right: 1.5 / 3.125rem;
        z-index: 2002;
    }
    .helpCenterDetails{
        background: rgb(255, 255, 255) ;
        width: 100%;
        height: 100vh  /* 46/50 */;
        box-sizing: border-box;
        padding-bottom: 2 / 3.125rem;
        position: relative;
        .helpCenterDetails-container{
            font-size: .32rem /* 16/50 */;
            padding: .4rem /* 20/50 */;
            .container-Q{
                margin-bottom: .2rem /* 10/50 */;
                display: flex;
                font-weight: 500;
            }
            .container-A{
                display: flex;
                .A-title{
                    color: rgba(255, 0, 0, 0.85);
                }
                .A-text{
                    color: rgb(92, 90, 90);
                    font-size: .28rem /* 14/50 */;
                }
            }
        }
        .helpCenterDetails-customer{
            width: 100%;
            position: absolute;
            text-align: center;
            bottom: 1.4rem /* 70/50 */;
            font-size: .24rem /* 12/50 */;
            color: rgb(125, 125, 125);
            .customer-text{
                color: #c83f3f;
            }
        }
    }
    
</style>
